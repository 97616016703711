import React from 'react'
import './AboutMePage.css';

const AboutMePage = () => {
  return (
    <div className="about-me-page">
        <h1 className="about-title">About Me</h1>
        <div className="content-container">
        <div className="about-me-content">
            <p>Hi my name's Paul, it's nice to meet you!</p>
            <p>
                I am a 3rd Year Computer Engineering student at Queen's University. I enjoy topics such as digital electronics, and computer architecture. My end goal is to become
                an ASIC engineer, or land job involved in chip design. 
            </p>
            <p>
                I made this website to practice using Node and React JS. If you take a look at my feed, all of that data was
                gathered by a NodeJS application. To make the feeds for Valorant and Queen's University I had to use web scraping
                and convert it to usable data. The CNN feed was easier as they provide their own rss feed link.
            </p>
        </div>
        </div>
        <div className="navbar">
        <a href="/" className="nav-link">
            <img src="link-icon.png" alt="Landing" className="navbar-icon" />
        </a>
        <a href="/about" className="nav-link">
            <img src="book-icon.png" alt="About" className="navbar-icon" />
        </a>
        <a href="/feed" className="nav-link active">
            <img src="newspaper-icon.png" alt="Feed" className="navbar-icon" />
        </a>
        </div>
  </div>
  )
}

export default AboutMePage