import React from 'react';
import './LandingPage.css';
import {Tooltip as ReactTooltip} from 'react-tooltip';



const LandingPage = () => {
  return (
    <div class="landing-page">
    <h1 class="title">Welcome to my website!</h1>
    <h3 class="subtitle">Feel free to connect with me and download my resume! If you want to email me, you can email me at paul2603fernando@gmail.com!</h3>
    <div class="button-container">
        <a href="https://www.linkedin.com/in/paul-fernando-1b603b217/" target="_blank" rel="noopener noreferrer" data-tip="Connect on LinkedIn">
        <button class="button">
            <img src="linkedin-round-line-icon.png" alt="LinkedIn" />
        </button>
        </a>
        <a href="https://github.com/paulkfernando" target="_blank" rel="noopener noreferrer" data-tip="Visit GitHub profile">
        <button class="button">
            <img src="github-icon.png" alt="GitHub" />
        </button>
        </a>
        <a href="https://www.instagram.com/paul2603fernando/" target="_blank" rel="noopener noreferrer" data-tip="Visit Instagram profile">
        <button class="button">
            <img src="instagram-icon.png" alt="GitHub" />
        </button>
        </a>
        <a href="/Paul Fernando Resume 2023 (1) (1).pdf" download data-tip="Download my resume">
        <button class="button">
            <img src="pdf-files-icon.png" alt="Resume" />
        </button>
        </a>
        <div class="tooltip">
        <ReactTooltip effect="solid" place="top" />
        </div>
    </div>
    <div class="navbar">
        <a href="/" class="nav-link">
        <img src="link-icon.png" alt="Landing" class="navbar-icon" />
        </a>
        <a href="/about" class="nav-link">
        <img src="book-icon.png" alt="About" class="navbar-icon" />
        </a>
        <a href="/feed" class="nav-link active">
        <img src="newspaper-icon.png" alt="Feed" class="navbar-icon" />
        </a>
    </div>
    </div>
  )
}

export default LandingPage