import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './FeedPage.css';

const FeedPage = () => {
  const [valFeedData, setValFeedData] = useState([]);
  const [queFeedData, setQueFeedData] = useState([]);
  const [cnnFeedData, setCnnFeedData] = useState([]);

  useEffect(() => {
    const fetchValFeedData = async () => {
      try {
        const url = 'https://paulfernando.me:5001/xml/getValFeed';
        const response = await axios.get(url);
        const data = response.data;

        setValFeedData(data);
      } catch (error) {
        console.error('Error fetching VAL feed data:', error);
      }
    };

    const fetchQueFeedData = async () => {
      try {
        const url = 'https://paulfernando.me:5001/xml/getQueFeed';
        const response = await axios.get(url);
        const data = response.data;

        setQueFeedData(data);
      } catch (error) {
        console.error('Error fetching QUE feed data:', error);
      }
    };

    const fetchCnnFeedData = async () => {
      try {
        const url = 'https://paulfernando.me:5001/xml/getCnnFeed';
        const response = await axios.get(url);
        const data = response.data;

        setCnnFeedData(data);
      } catch (error) {
        console.error('Error fetching CNN feed data:', error);
      }
    };

    fetchValFeedData();
    fetchQueFeedData();
    fetchCnnFeedData();
  }, []);

  return (
    <div className="feed-container">
      <h1 className="feed-title">My Feed</h1>

      <div className="que-feed">
        <img className='feed-icon' src='/queensicon.png' alt='QUEENS ICON'></img>
        <h2 className="feed-subtitle">Queensu ECE News</h2>
        <p className='feed-title'><a href='https://engineering.queensu.ca/ece/news.html' target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>See All {'>>'}</a></p>
        {queFeedData.map((item, index) => (
          <div className="feed-item" key={index}>
            <div className="feed-title">
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                {item.title}
              </a>
            </div>
            <div className="feed-date">
              Date: {item.pubDate.split(" ").slice(0, 4).join(" ")}
            </div>
          </div>
        ))}
      </div>

      <div className="cnn-feed">
      <img className='feed-icon' src='/cnn-logo-png-1810.png' alt='CNN ICON'></img>
        <h2 className="feed-subtitle">CNN Tech News</h2>
        <p className='feed-title'><a href='https://www.cnn.com/business/tech' target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>See All {'>>'}</a></p>
        {cnnFeedData.map((item, index) => (
          <div className="feed-item" key={index}>
            <div className="cnn-feed-title">
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                {item.title}
              </a>
            </div>
          </div>
        ))}
      </div>

      <div className="val-feed">
      <img className='feed-icon' src='/valicon.png' alt='VAL ICON'></img>
        <h2 className="feed-subtitle">Valorant Patch Notes</h2>
        <p className='feed-title'><a href='https://playvalorant.com/en-us/news/tags/patch-notes/' target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>See All {'>>'}</a></p>
        {valFeedData.map((item, index) => (
          <div className="feed-item" key={index}>
            <div className="feed-title">
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                {item.title}
              </a>
            </div>
            <div className="feed-date">
              Date: {item.pubDate.split(" ").slice(0, 4).join(" ")}
            </div>
          </div>
        ))}
      </div>

      <div className="navbar">
        <a href="/" className="nav-link">
            <img src="link-icon.png" alt="Landing" className="navbar-icon" />
        </a>
        <a href="/about" className="nav-link">
            <img src="book-icon.png" alt="About" className="navbar-icon" />
        </a>
        <a href="/feed" className="nav-link active">
            <img src="newspaper-icon.png" alt="Feed" className="navbar-icon" />
        </a>
        </div>


    </div>
  );
};

export default FeedPage;